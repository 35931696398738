import React from 'react';
import AnimatedPage from '../AnimatedPage';
import YoutubeEmbed from '../components/Navbar/YoutubeEmbed';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CssBaseline } from '@material-ui/core';
import Box from '@mui/material/Box';
import DividerWithText from '../components/Navbar/DividerWithText';
  
const Visuals = () => {


  return (
    
        <AnimatedPage>

                  <React.Fragment>
                  
                      <CssBaseline />

                      <Box sx={{ flexGrow: 1 }} style={{flexDirection:"column", alignItems:"center", justifyContent:"center", overflow:"hidden"}}>
                                                                  
                            <Grid container spacing={0}>
                        
                              <Grid item xs={12}>
                                        
                                        <Box sx={{ m: 30 }} />

                                      
                                                  <section id="visuals" />      
                                                          
                                                    <DividerWithText>

                                                        <Typography variant="h3" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon">Visuals</Typography>
                                                    
                                                    </DividerWithText>
                                               

                                                    <Box sx={{ width: '100%', display: 'flex' }}>
                                                          
                                                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                                                            
                                                            <Grid item xs={6}>
                                                                  <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" margin="20px">
                                                                       Intro [applied]
                                                                  </Typography> 
                                                                <YoutubeEmbed embedId="dTBqajo-l9E" />
                                                            
                                                            </Grid>
                                                            
                                                            <Grid item xs={6}>
                                                              <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" margin="20px">
                                                                       Programmed (beyond code)
                                                              </Typography>
                                                              <YoutubeEmbed embedId="Z0jch4gPIdA" />
                                                            
                                                            </Grid>
                                                            
                                                            <Grid item xs={6}>
                                                              <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" margin="20px">
                                                                       World of Pulsation
                                                              </Typography>
                                                            <YoutubeEmbed embedId="GcsYC7Cdu3k" />
                                                            
                                                            </Grid>
                                                            
                                                            <Grid item xs={6}>
                                                              <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" margin="20px">
                                                                       Awakening (lyric video)
                                                              </Typography>
                                                            <YoutubeEmbed embedId="u1vLdbJoXrQ" />
                                                             
                                                            </Grid>

                                                          </Grid>

                                                    </Box>                       

                                  </Grid>

                             </Grid>
                   
                   </Box>          
                    
              </React.Fragment>
                        
        </AnimatedPage> 
             
  );
};
  
export default Visuals;