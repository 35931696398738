import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    } from '@material-ui/core';
import metaMaskLogo from '../Elements/metamasklogo.png';    

export default function SimpleAccordion() {
  return (
    <div>
        <Accordion>

<AccordionSummary
    sx={{ backgroundColor: "black",
        outlineColor: "#4d044d",
        outlineStyle: "solid",
        outlineWidth: 1, }}
    expandIcon={<ExpandMoreIcon style={{color:"#fff"}}/>}
    aria-controls="panel1a-content"
    id="panel1a-header">
    <Typography variant="h6" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">
        
        What is an Airdrop?
        
    </Typography>
</AccordionSummary>

<AccordionDetails sx={{ backgroundColor: "black", justifyContent:"center", flexDirection:"column", alignItems:"center" }}>
    
    
    <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" margin="50px">
        <Box sx={{
                height: '10%',
                width: '10%',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                justifyContent:'center'
                }}
          
            />
            
            <Box sx={{ m: 2 }} />
            An Airdrop can be any kind of asset.
            <Box sx={{ m: 2 }} />
            In Web3 however, an airdrop can be a financial asset or an NFT asset.
            <Box sx={{ m: 2 }} />
            These assets are stored in "Wallets".
            <Box sx={{ m: 2 }} />
            The Blockchain space is rapidly growing.
            <Box sx={{ m: 2 }} />
            The world is still inventing the imaginable and unimaginable chain of possibilities.
            <Box sx={{ m: 2 }} />
            Far from realizing its all-encompassing capabilities.
            
            <Box sx={{ m: 10 }} />
            An NFT is a NON-FUNGIBLE asset which is data.
            <Box sx={{ m: 2 }} />
            Anything that can be digitally stored can be defined as an NFT.  
            <Box sx={{ m: 2 }} />
            An NFT is considered a one-of-a-kind "asset" tied to a smart contract.
            <Box sx={{ m: 2 }} />
            Smart contracts are pieces of code written in a computer programming language.
            <Box sx={{ m: 2 }} />
            Written in C# and wrapped for front-end execution.
            <Box sx={{ m: 2 }} />
            The smart contract writes the asset "hash" on the blockchain ledger.
            <Box sx={{ m: 2 }} />
            Many wonder why one could not just "right-click-and-save" as it pertains to NFTS.
            <Box sx={{ m: 2 }} />
            One absolutely can.
            <Box sx={{ m: 2 }} />
            However, the asset will not have the hash which is a part of the smart contract code.
            <Box sx={{ m: 2 }} />
            The actual asset is on the IPFS (InterPlanetary File Server) which generates the hash.
            <Box sx={{ m: 2 }} />
            The smart contract further solidifies which asset is "hashed".
            <Box sx={{ m: 2 }} />
            "Right-click-and-save" will also lack the immutable block info which ties it back to the creator/inventor/author.
            <Box sx={{ m: 2 }} />
            The smart contract has the code that gives it cryptographic value.
            <Box sx={{ m: 2 }} />
            This unlocks a whole new world of possibilities for creation.
            <Box sx={{ m: 2 }} />
            Giving creative power and control back to the creators and inventors.
            <Box sx={{ m: 2 }} />
            Away from companies and platforms.
            <Box sx={{ m: 2 }} />
            Away from their centralized Database.
            <Box sx={{ m: 2 }} />
            Protected on an immutable Distributed ledger.
            <Box sx={{ m: 2 }} />
            Accessible to Anyone.
            
            <Box sx={{ m: 10 }} />
            Why launch an Airdrop?
            <Box sx={{ m: 2 }} />
            It helps a creator to promote their work to early supporters.
            <Box sx={{ m: 2 }} />
            There are many reasons why an airdrop can help creators and supporters.
            <Box sx={{ m: 2 }} />
            An early supporter is incentivized according to project expansion.
            <Box sx={{ m: 2 }} />
            An early supporter can benefit in monetary gain of the Project.
            <Box sx={{ m: 2 }} />
            The asset can rise in value.
            <Box sx={{ m: 2 }} />
            The project can become completely Autonomous and form a DAO.
            <Box sx={{ m: 2 }} />
            (Decentralized Autonomous Organization)
            <Box sx={{ m: 2 }} />
            Governance by the people, for the people, with no limitations to geography.
            <Box sx={{ m: 2 }} />
            Unlocking another layer of endless possibilities.
            <Box sx={{ m: 2 }} />
            A whole new way of independence and civilization unlocks.
            <Box sx={{ m: 2 }} />
            Making an Airdrop the best type of gift to receive.
            <Box sx={{ m: 2 }} />
            Who knows what an Airdrop might unfold....

    </Typography>

</AccordionDetails>

</Accordion>

<Box sx={{ m: 2 }} />

      <Accordion>

            <AccordionSummary
                sx={{ backgroundColor: "black",
                    outlineColor: "#4d044d",
                    outlineStyle: "solid",
                    outlineWidth: 1, }}
                expandIcon={<ExpandMoreIcon style={{color:"#fff"}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant="h6" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">
                    
                    What is MetaMask?
                    
                </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: "black", justifyContent:"center", flexDirection:"column", alignItems:"center" }}>
                
                
                <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" margin="50px">
                    <Box sx={{
                            height: '10%',
                            width: '10%',
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                            justifyContent:'center'
                            }}
                        component="img"
                        alt="Web3 Wallet logo MetaMask"
                        src={metaMaskLogo}
                        />
                        
                        <Box sx={{ m: 2 }} />
                        MetaMask is a Web3 Wallet.
                        <Box sx={{ m: 2 }} />
                        The wallet connects to a number of "networks" on the blockchain.
                        <Box sx={{ m: 2 }} />
                        Ethereum, Polygon, Beacon, and Avalanche are some notable networks on the grand blockchain.
                        <Box sx={{ m: 2 }} />
                        Each wallet has a public and unique address. 
                        <Box sx={{ m: 2 }} />
                        Consider this your "bank account number".
                        <Box sx={{ m: 2 }} />
                        Wallets are free to install.
                        <Box sx={{ m: 2 }} />
                        <a href="https://metamask.io/" target="_blank" rel="noreferrer">Click here to setup MetaMask</a>
                        <Box sx={{ m: 2 }} />
                        p.s. PROTECT YOUR SEED PHRASE! 
                </Typography>

            </AccordionDetails>

      </Accordion>

      <Box sx={{ m: 2 }} />

      <Accordion>

            <AccordionSummary sx={{
                    backgroundColor: "black",
                    outlineColor: "#4d044d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                }}
                expandIcon={<ExpandMoreIcon style={{color:"#fff"}}/>}
                aria-controls="panel2a-content"
                id="panel2a-header">

                <Typography variant="h6" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" >
                    What happens when I click on "Connect to Wallet"?
                </Typography>

            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: "black", justifyContent:"center", flexDirection:"column", alignItems:"center" }}>

                    <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" margin="50px">                  
                        Signing the message, when MetaMask launches, enters your wallet address to the airdrop distribution list.
                        <Box sx={{ m: 2 }} />
                        As you have read above, there will be 360 Airdrops which you may qualify for.
                        <Box sx={{ m: 2 }} />
                        Your wallet address is public information.
                        <Box sx={{ m: 2 }} />
                        You can share your wallet address with anyone to send/receive crypto and NFT assets.
                        <Box sx={{ m: 2 }} />
                        Your Seed Phrase should always be private!
                        <Box sx={{ m: 2 }} />
                        Your seed phrase is the 12 words you have written down when setting up your wallet.
                    </Typography>

                </AccordionDetails>
      
      </Accordion>

    </div>
  );
}
