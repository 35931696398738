import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logovh from '../logo-100px.png';
import {
    NavLink,
    NavBtn,
    NavBtnLink,
} from '../components/Navbar/NavbarElements';
import { Moralis } from 'moralis';


const serverUrl = process.env.REACT_APP_SERVERURL;
const appId = process.env.REACT_APP_ID; 
Moralis.start({ serverUrl, appId })

async function login() {
                
    let user = Moralis.User.current();
    

        if(!user) {
            user = await Moralis.authenticate({signingMessage:"You are signing to enter the HHC Airdrop. \n 360 Airdrops will be made for this project + \n a future tokenization. \n \n It is important that you read this message. \n You have been summoned as a LightWorker \n to complete tasks for unity. \n To complete these tasks you must hold on to the \n Airdrop and tokenized assets which will aid \n in your missions as a LightWorker. \n Please remember, we are on Earth TOGETHER. \n \n Airdrop Updates: \n \n IG: @ReinaNatashaPlusPlus \n TW: @reinanatasha888"}); 
        } 

        alert("Your Wallet address is submitted to enter the Airdrop.", user);

    }

    
const pages = [
        
                      <NavLink 
                      display="flex"
                              to='./'
                              onClick={() => {
                                              let vh = document.getElementById("vh");
                                              vh && vh.scrollIntoView({ behavior: "smooth", block: "start"});
                                              }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The Book</Typography>
                      </NavLink>,

                      <NavLink 
                      display="flex"
                            to='./'
                            onClick={() => {
                                let airdrop = document.getElementById("airdrop");
                                airdrop && airdrop.scrollIntoView({ behavior: "smooth", block: "start"});
                                }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The Airdrop</Typography>
                      </NavLink>,
                                                          
                      <NavLink 
                      display="flex"
                            to='./'
                            onClick={() => {
                                let lp = document.getElementById("lp");
                                lp && lp.scrollIntoView({ behavior: "smooth", block: "start"});
                                }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The LightPaper</Typography>
                      </NavLink>,

                      <NavLink 
                      display="flex"
                            to='./'
                            onClick={() => {
                                let lp = document.getElementById("coin");
                                lp && lp.scrollIntoView({ behavior: "smooth", block: "start"});
                                }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The Crypto Coin</Typography>
                      </NavLink>,
                                                                  
                      <NavLink 
                      display="flex"
                            to='./music'
                            onClick={() => {
                                let music = document.getElementById("music");
                                music && music.scrollIntoView({ behavior: "smooth", block: "start"});
                            }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The Music</Typography>
                      </NavLink>,
                                                                        
                      <NavLink 
                      display="flex"
                            to='./visuals'
                            onClick={() => {
                                let visuals = document.getElementById("visuals");
                                visuals && visuals.scrollIntoView({ behavior: "smooth", block: "start"});
                                }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The Visuals</Typography>
                      </NavLink>,

                      <NavLink 
                      display="flex"
                            to='./'
                            onClick={() => {
                                let visuals = document.getElementById("intentions");
                                visuals && visuals.scrollIntoView({ behavior: "smooth", block: "start"});
                                }}>
                            <Typography variant="body2" component="div" style={{color:"#fff"}} align="center" fontFamily="Reza Zulmi Serif">The Intentions</Typography>
                      </NavLink>,

                      
                        <NavBtn varient="button" gutterBottom >
                                                                                                                                                                                                    
                                          <NavBtnLink 
                                              onClick={(login)} 
                                              to='./' 
                                              type="submit"
                                              >
                                          <Typography variant="body2" component="div" align="center" fontFamily="Reza Zulmi Serif">Connect to Wallet</Typography>
                                          </NavBtnLink>
                                                                                                                                                                                                    
                        </NavBtn>   
                            
             
    ];


    const ResponsiveAppBar = () => {
      const [anchorForNav, setAnchorForNav] = React.useState(null);
    
      const handleOpenNavMenu = (event) => {
        setAnchorForNav(event.currentTarget);
      };
    
      const handleCloseNavMenu = () => {
        setAnchorForNav(null);
      };


  return (

        <AppBar style={{ background: '#000000' }} position="fixed">

              <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column' },
                        alignItems: 'center',
                        height: '80px'
                      }}>

                  <Toolbar disableGutters>
                        
                            <Grid item sx= {{ flexGrow: "1", display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex'} }}
                                  flexWrap
                                  component="div" 
                                  
                                >
                                  <img src={logovh} 
                                      className="Logovh" 
                                      alt="" 
                                      to='./' 
                                      height="50px"
                                      activestyle="true" 
                                      onClick={() => {
                                          let vh = document.getElementById("vh");
                                          vh && vh.scrollIntoView({ behavior: "smooth", block: "start"});
                                          }}
                                  />
                            </Grid>

                            <Grid item sx={{ flexGrow: "1", display: { xs: 'flex', md: 'none' } }} style={{ color: '#0000000' }}>

                              <MenuIcon
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                              >
                              </MenuIcon>

                              <Menu
                                display="flex"
                                id="menu-appbar"
                                style = {{anchorForNav:{anchorForNav}}}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                keepMounted
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                open={Boolean(anchorForNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                  display: { xs: 'flex', md: 'none' },
                                }}
                              >
                                {pages.map((page, id1, id2) => (
                                  <MenuItem style={{ paddingTop: 0, backgroundColor: '#000', width: '300px', height: '80px' }} variant="contained" key={pages.id1} value={page} onClick={handleCloseNavMenu}>
                                    <Typography>{page}</Typography>
                                  </MenuItem>
                                ))}
                              </Menu>

                            </Grid>

                            <Grid item sx={{ flexGrow: "1", display: { xs: 'flex', md: 'none' } }}
                              component="div"
                               
                            >
                              <img src={logovh} 
                                  className="Logovh" 
                                  alt="" 
                                  to='./' 
                                  height="50px"
                                  activestyle="true" 
                                  onClick={() => {
                                      let vh = document.getElementById("vh");
                                      vh && vh.scrollIntoView({ behavior: "smooth", block: "start"});
                                  }}
                              />
                            </Grid>

                                  <Grid sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                      {pages.map((page, id1, id2) => (
                                      <Button
                                          key={pages.id2} value={page}
                                          onClick={handleCloseNavMenu}
                                          sx={{ my: 10, color: 'white' }}
                                      >
                                          {page}
                                      </Button>
                                      ))}
                                  </Grid>
                  
                      </Toolbar>
                  
                  </Box>
  
          </AppBar>
                    
  );
};
export default ResponsiveAppBar;
