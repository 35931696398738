import React from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { 
        Grid,
        Box 
        } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './pages';
import Visuals from './pages/visuals';
import Music from './pages/music';
import { FaYoutube, FaInstagram, FaTwitter, FaFacebook } from 'react-icons/fa';
import DividerWithText from './components/Navbar/DividerWithText'; 
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-132321536-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);



function App() {

 
   
  return (
 
    <React.Fragment>

        <CssBaseline />

            <Box sx={{ flexGrow: 1 }}>

              <Grid container spacing={0}>

                          <Grid item xs={12}>

                         

                                    <div className="App">
                                      <header className="App-header">

                                      <Grid item xs={12} display="flex" style={{flexDirection:"column"}} overflow="hidden">
                                      
                                              <Router>
                                                <Navbar />
                                                    
                                                        <Routes>
                                                            <Route path='/' exact element={<Home />} />
                                                            <Route path='/music' exact element={<Music />} />
                                                            <Route path='/visuals' exact element={<Visuals />} />    
                                                        </Routes>
                                                      
                                              </Router>
                                        </Grid>
                                      
                                      </header>

                          
                                    
                                <Grid item xs={12} display="flex" style={{flexDirection:"column"}} overflow="hidden">
                                      <DividerWithText>
                                          <Typography variant="h6" component="div" style={{color:"#fff", flexDirection:"column"}} align="center" fontFamily="Meddon" margin="50px" display="flex">Vibrate&nbsp;Higher&nbsp;Productions</Typography>
                                      </DividerWithText>  
                                </Grid>      
                                      
                                <Grid item xs={12} display="flex" style={{flexDirection:"column"}} overflow="hidden">               
                                        <div className="Content-wrap" align="center">
                                                      <a href="https://www.youtube.com/channel/UCzOIml48XyqiTfcrPSKDucw" target="_blank" rel="noreferrer">
                                                        <FaYoutube size={50} color='grey'/>
                                                      </a> 

                                                      <a href="https://www.instagram.com/reinanatashaplusplus/" target="_blank" rel="noreferrer">
                                                        <FaInstagram size={50} color='grey'/>
                                                      </a>
                                                    
                                                      <a href="https://twitter.com/reinanatasha888" target="_blank" rel="noreferrer">  
                                                        <FaTwitter size={50} color='grey'/> 
                                                      </a>

                                                      <a href="https://www.facebook.com/ReinaNatasha" target="_blank" rel="noreferrer">
                                                        <FaFacebook size={50} color='grey'/>
                                                      </a>
                                          </div>
                                         
                                          <DividerWithText>
                                            <Typography variant="h6" component="div" style={{color:"#fff", flexDirection:"column"}} align="center" fontFamily="Meddon" margin="50px" display="flex">&copy;-33,980</Typography>
                                        </DividerWithText>
                                        
                                </Grid>
                                      
                                      
                        </div>    


                    </Grid>

               </Grid>

          </Box>

    </React.Fragment>

  );
}

export default App;