import React, { useEffect } from 'react';
import ResponsiveAppBar from '../ResponsiveAppBar';


const NavBar = () => {


                
                  useEffect(() => {
                        let url = window.location.href.split("/");
                        let target = url[url.length - 1].toLowerCase();
                        let element = document.getElementById({ behavior: "smooth", block: "start" });
                    }, []);

            

            return (
                
                <>
                    <ResponsiveAppBar/>
                  
                </>

            )
        }

export default NavBar;


