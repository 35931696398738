import React from "react";
import AnimatedPage from '../AnimatedPage';
import Accordion from '../components/Elements/Accordion';
import DividerWithText from '../components/Navbar/DividerWithText';
import {
      CssBaseline,
      Grid,
      Box
      } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import WOPVideo from "../assets/worldofpulsation.mov";
import KingTut from "../assets/KingTut.mov";
import HHCvideo from "../assets/HHCTeaser.mov";
import HHCImage from "../assets/cover-HHC.jpg";
import Button from '@mui/material/Button';

const Home = () => {


  return (
                        
                        
                        <AnimatedPage>

                              <React.Fragment>

                                          <CssBaseline />
                                          
                                                      <Box sx={{ flexGrow: 1 }}
                                                            style={{flexDirection:"column", alignItems:"center"}} overflow="hidden">

                                                                  <Box sx={{ m: 10 }} />
                                                                  <Grid container spacing={0}>

                                                                  <Grid item xs={12} display="flex" style={{flexDirection: "column"}} overflow="hidden">

                                                                        
                                                                                    <Box sx = {12} height="30vh" display="flex" style={{flexDirection: "column"}}>
                                                                                      
                                                                                          <Grid container sx={6}
                                                                                                component="video"
                                                                                                display="flex"
                                                                                                style={{alignItems:"center", flexDirection:"column"}}
                                                                                                muted
                                                                                                autoPlay={"autoplay"}
                                                                                                preload="auto"
                                                                                                loop
                                                                                                alt="King Tut"
                                                                                                src={KingTut}>
                                                                                          </Grid>
                                                                                          
                                                                                    </Box> 

                                                                                    <Box sx={{ m: 20 }} />

                                                                                    <Box sx = {6} display="flex" style={{flexDirection: "column"}}>
                                                                                          
                                                                                          <Typography variant="h5" component="div" style={{background: "transparent", color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon"  display="flex">
                                                                                                      Welcome to the
                                                                                          </Typography>
                                                                                                      <Box sx={{ m: 2 }} />
                                                                                          <Typography variant="h4" component="div" style={{background: "transparent", color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">
                                                                                                      Genesis Conscious Web
                                                                                          </Typography>
                                                                                          

                                                                                    </Box>

                                                                                    <Box sx={{ m: 10 }} />
                                                                              
                                                                                    <Box sx = {6} display="flex" style={{flexDirection: "column"}}> 
                                                                                          
                                                                                          <Typography variant="body1" gutterBottom component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" >
                                                                                                High Vibrations from my Soul to your Soul.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                I am a Decentralized Artist 
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                Vibrating you to 360 Senses
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                Creating for Love, Unity, and raising the Human Vibration.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                Gaia is my employer and powers us all to create beyond our 5 Senses. 
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                We are on Earth together, at this very moment, for a reason.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                It is to Thrive in our endless quest to
                                                                                          </Typography>

                                                                                          <Box sx={{ m: 5 }} />

                                                                                          <Typography variant="h4" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" >      
                                                                                                
                                                                                                Vibrate Higher
                                                                                          
                                                                                          </Typography>
                                                                                    </Box>
                                                                  </Grid>

                                                                  <Box sx={{ m: 20 }} />

                                                                  

                                                                  <Grid item xs={12} display="flex" style={{flexDirection: "column", color: '#381533'}}>
                                                                  <section id="vh" />
                                                                  <Box sx={{ m: 10 }} />
                                                                        
                                                                        <Box sx = {12} display="flex" style={{color: '#381533', flexDirection: "column" }}>  

                                                                  
                                                                              <DividerWithText>
                                                                                    
                                                                                    <Typography variant="h5" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">Housing&nbsp;Higher&nbsp;Consciousness</Typography>
                                                                                    
                                                                              </DividerWithText>

                                                                              <DividerWithText>
                                                                                    
                                                                                    <Typography variant="h6" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">Vibrate&nbsp;to&nbsp;360&nbsp;Senses</Typography>
                                                                                    
                                                                              </DividerWithText>


                                                                        </Box>
                                                                        <Box sx={{ m: 10 }} />

                                                                        <Box sx = {12} height="50vh" display="flex" style={{flexDirection: "column", alignItems:"center"}}>

                                                                              <Box item sx={12} 
                                                                                   style={{ minHeight: '20vh', minWidth: '20vw', justifyContent: 'center', alignItems:'center', boxShadow:3, borderRadius: 2 }}
                                                                                   component="img"
                                                                                   alt="Housing Higher Consciousness Book Image"
                                                                                   src={HHCImage}>
                                                                              </Box>
     
                                                                        </Box>

                                                                        <Box sx={{ m: 10 }} />
                                                                              <Box sx = {12}  display="flex" style={{flexDirection: "column", alignItems:"center"}}>
                                                                              <Button style = {{ background: '#fff', color: '#4d044d'}} variant="contained" href="https://www.lulu.com/en/us/shop/reena-khilawan/housing-higher-consciousness/hardcover/product-ev2nny.html?page=1&pageSize=4" target="_blank" rel="noreferrer">
                                                                                    <Typography variant="body2" component="div" align="center" fontFamily="Reza Zulmi Serif">BUY THIS BOOK</Typography>
                                                                              </Button>

                                                                  
                                                                        </Box>

                                                                        

                                                                        <Box sx={{ m: 10 }} />

                                                                        <Box sx = {12} display="flex" style={{flexDirection: "column"}}>            
                                                                              
                            
                                                                              <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" margin="50px">
                                                                                    Housing Higher Consciousness - Vibrate to 360 Senses
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    was written with Love and Unity as intention.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    The 202 pages of this hardcover book dive in the realms of
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    self-belief, self-elevation, Nature and the power of the Universe.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Although this book took a lifetime of downloads to manifest,
                                                                                    <Box sx={{ m: 1 }} />  
                                                                                    the words to the book were compiled over the course of four years.
                                                                                    <Box sx={{ m: 8 }} />                                                                                    
                                                                                   
                                                                                    Housing Higher Consciousness - Vibrate to 360 Senses
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    can be purchased through book distributor <a href="https://www.lulu.com/en/us/shop/reena-khilawan/housing-higher-consciousness/hardcover/product-ev2nny.html?page=1&pageSize=4" style={{color:"#fff"}} target="_blank" rel="noreferrer">LULU</a>
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Alternatively, the HHC book is enroute for Global distribution.
                                                                                    <Box sx={{ m: 8 }} />
                                                                                    
                                                                              </Typography>

                                                                              <Typography variant="h5" component="div" style={{color:"gray"}} align="center" fontFamily="Meddon">
                                                                                    Watch this space and socials as more releases become available
                                                                              </Typography>
                                                                        

                                                                        </Box> 

                                                                        <Box sx={{ m: 8 }} />                             

                                                                  
                                                                  </Grid>

                                                                        <Box sx={{ m: 10 }} />
                                                            
                                                                  <Box sx={{ m: 20 }} />

                                                                  <Grid item xs={12} display="flex" style={{flexDirection: "column"}}>
                                                                  <section id="airdrop" />
                                                                  
                                                                        
                                                                        <Box sx = {12} display="flex" style={{color: '#381533', flexDirection: "column"}}>  

                                                                  
                                                                              <DividerWithText>
                                                                                    
                                                                                    <Typography variant="h4" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">The&nbsp;Airdrop</Typography>
                                                                                    <Typography variant="h6" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">Rewarding&nbsp;Early&nbsp;Supporters</Typography>
                                                                                    
                                                                              </DividerWithText>
                                                                        </Box>

                                                                        <Box sx={{ m: 10 }} />

                                                                        <Box sx = {12} height="50vh" display="flex" style={{flexDirection: "column", alignItems:"center"}}>
                                                                              <Box item sx={12} 
                                                                              style={{ minHeight: '20vh', minWidth: '20vw', alignItems:"center"}}
                                                                              component="video"
                                                                              loop
                                                                              muted
                                                                              autoPlay={"autoplay"}
                                                                              preload="auto"                                                               
                                                                              lg={{
                                                                                          maxHeight: { xs: 233, md: 167 },
                                                                                          maxWidth: { xs: 350, md: 250 },
                                                                                    }}
                                                                              alt="HHC Promo"
                                                                              src={HHCvideo}>
                                                                              </Box>
                                                                        </Box>     

                                                                        <Box sx={{ m: 10 }} />


                                                                        <Box sx = {12} display="flex" style={{flexDirection: "column"}}>            
                                                                              <Typography variant="h5" component="div" style={{color:"gray"}} align="center" fontFamily="Reza Zulmi Serif">
                                                                                          Housing Higher Consciousness - Vibrate to 360 Senses
                                                                              </Typography>
                                                                              <Box sx={{ m: 3 }} />
                                                                              <Typography variant="h2" component="div" style={{color:"gray"}} align="center" fontFamily="Reza Zulmi Serif">
                                                                                          HHC
                                                                              </Typography>

                                                                              <Box sx={{ m: 3 }} />

                                                                              <Typography variant="h5" component="div" style={{color:"gray"}} align="center" fontFamily="Avenir">
                                                                                          A Tokenized NFT Project
                                                                              </Typography>

                                                                              <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" margin="50px">                                                                                    
                                                                                    My new soul-work will launch in Fungible and Non-Fungible form.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Over the years, I have gathered puzzle pieces to create the "Conscious Web".
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    This mindset paved how I would partake and innovate the conscious distributed web.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    This first drop of the Non-Fungible part of my work will be my e-book.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    The e-book of Housing Higher Consciousness - Vibrate to 360 Senses
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    will launch under a smart contract.
                                                                                    <Box sx={{ m: 1 }} /> 
                                                                                    Each tokenized asset will reward the holder in the future.
                                                                              </Typography>
                                                                              <Box sx={{ m: 5 }} />
                                                                        </Box>
                                                            </Grid>             

                                                                  <Box sx={{ m: 5 }} />

                                                            <Grid item xs={12} style={{ backgroundColor: '#381533' }}>

                                                                              <Box sx = {12} display="flex" style={{flexDirection: "column"}}>
                                                                              <Box sx={{ m: 10 }} />
                                                                                    <Typography variant="h2" component="div" style={{color:"white"}} align="center" fontFamily="Reza Zulmi Serif">
                                                                                          HHC
                                                                                    </Typography>
                                                                                    
                                                                                    <Box sx={{ m: 5 }} />

                                                                                          <Typography variant="h3" component="div" style={{color:"#fff", alignItems:"center"}} align="center" fontFamily="Roboto">
                                                                                                                           
                                                                                                                              will have 360,360 assets
                                                                                                                              <Box sx={{ m: 1 }} />

                                                                                          </Typography>
                                                                                          <Typography variant="h6" component="div" style={{color:"#fff", alignItems:"center"}} align="center" fontFamily="Roboto"> 
                                                                                                                              programmed on the Smart Contract
                                                                                                                              <Box sx={{ m: 1 }} />
                                                                                          </Typography>                                      
                                                                                          <Typography variant="h3" component="div" style={{color:"#fff", alignItems:"center"}} align="center" fontFamily="Roboto">
                                                                                                                              - 
                                                                                                                              <Box sx={{ m: 1 }} />
                                                                                                                              360 Airdrops
                                                                                                                              <Box sx={{ m: 1 }} />
                                                                                                                              =
                                                                                                                              <Box sx={{ m: 1 }} />
                                                                                                                              360,000 tokenized assets
                                                                                          </Typography> 

                                                                                    <Box sx={{ m: 10 }} />
                                                                                    
                                                                                    <DividerWithText/>  
                                                                              </Box>
                                                            </Grid>
                                                                                         
                                                                                          
                                                                        <Box sx={{ m: 10 }} />

                                                            <Grid item xs={12} style={{ color: 'white' }}>

                                                                        <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">                  
                                                                                          The manifestation codes of HHC
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          will serve as a code guide for creating the 'Conscious Web' together.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          It is a holding asset for future planting, ceations, and building.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          Signing the wallet message enters your wallet address to qualify for one of the 360 Airdrops.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          After this drop, the Non-Fungible assets will be available in tokenized form.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          Each time a new piece of innovation or project launches,
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          this holding asset will reward its holders. 
                                                                                          <Box sx={{ m: 1 }} />
                                                                        </Typography>

                                                                        <Box sx={{ m: 30 }} />
                                                                       
                                                                       
                                                                        <Grid container  display="flex" style={{justifyContent:"center"}}>            
                                                                             
                                                                           <Accordion/>

                                                                        </Grid>   
                                          
                                                                       <Box sx={{ m: 30 }} />

                                                            </Grid>

                                                               
                                                                  <Box sx={{ m: 10 }} />                 
                                                                 

                                                            <Grid item xs={12} style={{ color: '#381533', backgroundColor: '#381533', flexDirection: "column" }} display="flex" >

                                                                  <Box sx={{ m: 10 }} /> 
                                                            
                                                                  <section id="lp"/>

                                                                        <DividerWithText>

                                                                        <Typography variant="h4" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">Light&nbsp;Paper</Typography>
                                                                        
                                                                        </DividerWithText>


                                                                        <Box sx={{ m: 10 }} />
                                                                        

                                                                        <Typography variant="h3" component="div" style={{color:"gray"}} align="center" fontFamily="Avenir">
                                                                                    $VBRH - The Coin
                                                                        </Typography>

                                                                        <Box sx={{ m: 10 }} />

                                                                        <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">                  
                                                                                    The $VBRH coin resides on the Polygon BlockChain. 
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    The Vibrate Higher Coin is an asset that will serve for building the 
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    different parts and gateways for the 'Conscious Web'.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    There are 369,000,000 coins programmed in the smart contract,
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Through the future ICO (Initial Coin Offering) we will 
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    build, hire, and expand the platforms of human vibration.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Creating innovative ways to grow, feed, house, provide and proliferate 
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    The conscious ways of Earth.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Together we will build, feed, and Vibrate to 360 Senses.
                                                                        </Typography>

                                                                        <Box sx={{ m: 10 }} />

                                                                        <Typography variant="h5" component="div" style={{color:"gray"}} align="center" fontFamily="Meddon">
                                                                                    Elaborate LightPaper Coming Soon
                                                                        </Typography>

                                                                        <Box sx={{ m: 10 }} />      
                                                            </Grid>

                                                                  <Box sx={{ m: 10 }} />

                                                            <Grid item xs={12} display="flex" style={{ color: '#381533', flexDirection: "column" }}>
                                                            <section id="coin" />                        
                                                                        <DividerWithText>

                                                                              <Typography variant="h4" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">Tokenomics</Typography>
                                                                        
                                                                        </DividerWithText>

                                                                        <Box sx={{ m: 10 }} />
                                                                        
                                                                                    
                                                                        <Typography variant="h4" component="div" style={{color:"gray"}} align="center" fontFamily="Avenir">
                                                                                    The Cap for $VBRH is 369,000,000 coins.
                                                                        </Typography>    
                                                                        
                                                                        <Box sx={{ m: 10 }} />
                                                                        
                                                                        <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">                   

                                                                                          $VBRH will provide the funding to build, expand, and serve as liberation for humanity.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          Humanity has learned through Blockchain innovations that money, and power are mere illusions.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          A world leading virus, and wars, have caused us all to see the world through our core of Love.
                                                                                          <Box sx={{ m: 2 }} />
                                                                                          $VBRH Coin holders will have the title 'LightWorkers'.
                                                                                          <Box sx={{ m: 1 }} /> 
                                                                                          Our first mission is to build a lasting eco-system that will help humanity as a whole.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          Together we will help each other with basic Humanity.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          In this eco-system we will start by building a platform.
                                                                                          <Box sx={{ m: 1 }} />                                                                                          
                                                                                          This platform will be the result of a DAO (Decentralized Autonomous Organization).
                                                                                          <Box sx={{ m: 3 }} />
                                                                                          Currently I am working in solo capacity.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          However, I trust that the LightWorkers I need are finding their way to me.
                                                                                          <Box sx={{ m: 1 }} />
                                                                                          It is, after all, OUR mission to expand the 'Conscious Web'.                                                                                               
                                                                        </Typography>
                                                                        
                                                            </Grid>            
                                                                       
                            
                                                                        <Box sx={{ m: 10 }} />

                                                                 
                                                                  <Grid item xs={12} style={{ color: '#381533', backgroundColor: '#381533', flexDirection: "column" }} display="flex">
                                                                  <section id="intentions" />
                                                                  
                                                                        <Box sx={{ m: 10 }} />

                                                                              <DividerWithText>

                                                                                    <Typography variant="h4" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">Intentions</Typography>
                                                                  
                                                                              </DividerWithText>

                                                                              <Box sx={{ m: 10 }} />

                                                                              <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir" margin="50px"> 
                                                                                                I move in Love and Empathy.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                My intentions are always to create anything that can benefit human vibration;
                                                                                                <Box sx={{ m: 1 }} />                              
                                                                                                where holding a tokenized asset can help build a new way of living.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                It is my intention to provide solutions.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                It is my intention to help the artist create and the inventor invent.
                                                                                                <Box sx={{ m: 2 }} />
                                                                                                Whether it is designing, music, writing, programming, producing, presenting, post-producing, or innovating,
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                I am currently solely responsible for everything that I create.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                It is only through being open and honest, that I may grow my soul-work.
                                                                                                <Box sx={{ m: 3 }} />
                                                                                                May your minds be open to connect with me as we can CREATE TOGETHER.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                Revealing and moving through my own identity is the only way to connect to your soul.
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                Through this path I welcome you to
                                                                                                <Box sx={{ m: 1 }} />
                                                                                                'Vibrate to 360 Senses'.
                                                                              </Typography>
                                                                  
                                                                  </Grid> 

                                                                        <Box sx={{ m: 10 }} />           
                                                            
                                                                  <Grid item xs={12} style={{ color: 'white', flexDirection: "column"}} display="flex">

                                                                              <Box sx = {12} height="100vh" display="flex" style={{ flexDirection: "column", alignItems:"center"}}>
                                                                                                <Box item sx={12} 
                                                                                                style={{ minHeight: '20vh', minWidth: '20vw', justifyContent: 'center', alignItems:"center"}}
                                                                                                component="video"
                                                                                                loop
                                                                                                muted
                                                                                                autoPlay={"autoplay"}
                                                                                                preload="auto"
                                                                                                alt="Trippy video"
                                                                                                src={WOPVideo}>
                                                                                                </Box>
                                                                              </Box>     

                                                                  

                                                                         <Box sx={{ m: 10 }} />

                                                                              

                                                                              <Typography variant="h6" component="div" style={{ color:"#fff", flexDirection: "column" }} align="center" fontFamily="Meddon" display="flex">
                                                                                          
                                                                                          See you in the next phase where we collectively              
                                                                                    
                                                                              </Typography>

                                                                              <Box sx={{ m: 10 }} />
                                                                              
                                                                              
                                                                              <Typography variant="h2" component="div" style={{color:"#fff", flexDirection: "column"}} align="center" fontFamily="Meddon" display="flex">

                                                                                          Vibrate Higher
                                                                                    
                                                                              </Typography>

                                                                              <Box sx={{ m: 10 }} />

                                                                  </Grid>
                                                  
                                                           

                                                  </Grid>                  

                                          </Box>
                                                                                          
                              </React.Fragment>

                        </AnimatedPage>
       
            );
      };

  
export default Home;