import React from 'react';
import AnimatedPage from '../AnimatedPage';
import DividerWithText from '../components/Navbar/DividerWithText';
import Typography from '@mui/material/Typography';
import { 
      CssBaseline, 
      Grid,
      Box 
      } from '@material-ui/core';
import LPanimation from "../assets/Like-Psy-CoverAnim.mp4";
import Entheo from "../assets/Reina-Natasha-Entheogens-Lyrics.mp4";

  
const Music = () => {

  
      return (
     
            <AnimatedPage>
                  
                  <React.Fragment>
                  
                        <CssBaseline />
                              
                              <Box sx={{ flexGrow: 1 }} style={{flexDirection:"column", alignItems:"center", justifyContent:"center", overflow:"hidden"}}>
                                                                  
                                          <Grid container spacing={0}>

                                               <Grid item xs={12}>
                                               <Box sx={{ m: 30 }} />
                                                
                                                      <section id="music" />
                                    
                                                            <DividerWithText>

                                                                  <Typography variant="h3" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon">Like&nbsp;Psychedelics</Typography>
                                                            
                                                            </DividerWithText>

                                                            <Box sx={{ m: 10 }} />
                                                      
                                                                  <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" margin="50px">
                                                                                    The forward thinking Musical Album
                                                                  </Typography>

                                                                  <Box sx={{ m: 10 }} />

                                                                              <div className="Body-elements">
                                                                                    <video autoPlay loop width={480}>
                                                                                          <source src={LPanimation} type="video/mp4"></source>      
                                                                                    </video> 
                                                                              </div>

                                                                  <Box sx={{ m: 10 }} />
                                                                              
                                                                              <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">
                                                                              
                                                                                    Like Psychedelics was manifested many years ago.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    The production however started and halted in 2013,
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    and manifested back into existence in 2018.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    On February 2, 2020, it was released into the world.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Each song was produced and written by Me,
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    and Executive Produced by my 'musical soul-mate'
                                                                                          
                                                                              </Typography>

                                                                  <Box sx={{ m: 10 }} />            
                                                                                    
                                                                              <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon">Anonym Product</Typography>

                                                                   <Box sx={{ m: 10 }} />            

                                                                              <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">
                                                                              
                                                                                    'Like Psychedelics' contains 20 songs.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Composed and Recorded in Divine Order.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    However, it does not matter where you start listening since each unique song,
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    and production takes you on a journey through the realms.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    This work consists of many natural elements.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Including the recording of plants, nature sounds, and the heartbeat of my late dog, Tipsy Kay.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Below is the Spotify Playlist, but may also be embraced on Web3 platforms such as 
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    <a href="https://audius.co/reinanatasha">Audius.</a>
                                                                           
                                                                              </Typography>

                                                                  <Box sx={{ m: 10 }} />         

                                                                              <div className="Body-elements" >
                                                                                    <iframe src="https://open.spotify.com/embed/album/37cSM8jZTIOpqIOoN141iG" title="spotify" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                                                                              </div> 

                                                                  <Box sx={{ m: 20 }} />      

                                                                  <Grid item xs={12} display="flex" style={{flexDirection: "column", backgroundColor: '#381533'}}align="center">
                                                             

                                                                        <DividerWithText><Typography variant="h4" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon">Like&nbsp;Psychedelics&nbsp;Music&nbsp;nft</Typography></DividerWithText>

                                                                        <Box sx={{ m: 3 }} />
                                                            
                                                                        <Typography variant="h5" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon" margin="50px">
                                                                                          on MintSongs
                                                                        </Typography>

                                                                        <Box sx={{ m: 20 }} /> 

                                                                        <Grid item xs={12} display="flex" style={{flexDirection: "column", backgroundColor: '#381533'}}align="center">

                                                                        <iframe src="https://www.mintsongs.com/u/ReinaNatasha" width="600px" height="400px" title="Music NFT Reina Natasha"/>

                                                                        </Grid>

                                                                        <Box sx={{ m: 10 }} />   

                                                                  </Grid>   
                                                                            
                                                      </Grid>

                                                      <Box sx={{ m: 10 }} />

                                                      <Grid item xs={12}>

                                                                              <DividerWithText>
                                                                                    <Typography variant="h3" component="div" style={{color:"#fff"}} align="center" fontFamily="Meddon">Entheogens</Typography>
                                                                              </DividerWithText>

                                                                              <Box sx={{ m: 10 }} />

                                                                              <Typography variant="body1" component="div" style={{color:"#fff"}} align="center" fontFamily="Avenir">
                                                                              
                                                                                    My song 'Entheogens' was released in 2018 while working on 'Like Psychedelics'.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    A song about the magical mushroom Amanita Muscaria.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    The Elusive reset of Gaia, and all of Mother Nature.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    It contains 2 versions. The regular mix and the 'Vibrate Higher Mix'.
                                                                                    <Box sx={{ m: 1 }} />
                                                                                    Entheogens will have more notable mixes in the future.

                                                                              </Typography>

                                                                              <Box sx={{ m: 10 }} />     
                                                                             
                                                                           
                                                                             <div className="Body-elements">
                                                                                         <iframe src="https://open.spotify.com/embed/album/1T4l5GXagmkyc5netscdhl" title="spotify-embed" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media" margin="150px"></iframe>
                                                                             </div>

                                                                              <Box sx={{ m: 10 }} />

                                                                              <Box sx = {12} height="50vh" display="flex" style={{justifyContent:'center', flexDirection:"column", alignItems:"center"}}>
                                                                                    <Box item sx={12} 
                                                                                          style={{ minHeight: '20vh', minWidth: '20vw', justifyContent:'center', alignItems:"center"}}
                                                                                          component="video"
                                                                                          loop
                                                                                          muted
                                                                                          autoPlay={"autoplay"}
                                                                                          preload="auto"                                                               
                                                                                          lg={{
                                                                                                      maxHeight: { xs: 233, md: 167 },
                                                                                                      maxWidth: { xs: 350, md: 250 },
                                                                                                }}
                                                                                          alt="Entheogens Lyrics"
                                                                                          src={Entheo}>
                                                                                    </Box>
                                                                              </Box>        

                                                                  

                                                                              <Box sx={{ m: 10 }} />

                                                </Grid>
                                                                                   
                                          </Grid>                  

                                    </Box> 
                                  
                  </React.Fragment>

            </AnimatedPage> 
                
  );
};
  
export default Music;